<template>
  <fr-button size="sm" variant="outline" icon="language">
    {{ state.selected.value.label }}
    <q-tooltip> Language picker </q-tooltip>

    <q-menu class="m-0 min-w-[150px] p-0 shadow-none" :offset="[0, 10]">
      <div class="fr-card">
        <q-list>
          <template
            v-for="language in state.languages.value"
            :key="language.label"
          >
            <q-item
              clickable
              :class="
                state.selected.value.value === language.value
                  ? 'text-cyan-500'
                  : ''
              "
              @click="
                () => {
                  state.selected.value = language
                  $i18n.locale = language.value
                }
              "
            >
              <q-item-section
                class="flex flex-row items-center justify-between"
              >
                {{ language.label }}

                <span
                  v-if="language.isAI"
                  class="text-xs font-semibold text-fr-secondary-500"
                >
                  AI
                </span>

                <q-tooltip v-if="language.isAI" :offset="[0, 10]">
                  this language is automatically translated
                </q-tooltip>
              </q-item-section>
            </q-item>
            <q-separator />
          </template>
        </q-list>
      </div>
    </q-menu>
  </fr-button>
</template>

<script setup lang="ts">
const state = toRefs(
  reactive<{
    languages: {
      label: string
      shortLabel: string
      value: 'en-za' | 'de-de' | 'af-za' | 'fr-fr'
      isAI?: boolean
    }[]
    selected: {
      label: string
      shortLabel: string
      value: 'en-za' | 'de-de' | 'af-za' | 'fr-fr'
      isAI?: boolean
    }
  }>({
    languages: [
      {
        label: 'English',
        shortLabel: 'Eng',
        value: 'en-za',
        isAI: false
      },
      {
        label: 'German',
        shortLabel: 'Ger',
        value: 'de-de',
        isAI: true
      },
      {
        label: 'Afrikaans',
        shortLabel: 'Afr',
        value: 'af-za',
        isAI: true
      },
      {
        label: 'French',
        shortLabel: 'Fre',
        value: 'fr-fr',
        isAI: true
      }
    ],
    selected: {
      label: 'English',
      shortLabel: 'Eng',
      value: 'en-za',
      isAI: false
    }
  })
)
</script>
