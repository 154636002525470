<template>
  <fr-page
    class="m-6 !w-full md:w-[40vw] md:min-w-[40vw] lg:w-[60vw] lg:min-w-[60vw]"
  >
    <fr-card>
      <fr-card-header
        title="Service Messages"
        subtitle="Create, edit, and delete service messages"
        show-close-button
      >
      </fr-card-header>

      <fr-card-body class="flex !flex-row gap-2">
        <div class="flex flex-1 flex-col gap-2">
          <p class="text-lg font-semibold">Create Service Message</p>
          <q-input
            v-model="newMessage.message"
            label="Message"
            placeholder="Enter a message"
            outlined
          />

          <q-input
            v-model="newMessage.user"
            label="User"
            placeholder="Enter a user"
            outlined
          />
          <div>
            <fr-button
              icon="fal fa-plus"
              :loading="isCreatingServiceMessage"
              @click="
                () =>
                  createServiceMessage({
                    message: newMessage.message,
                    user: newMessage.user
                  })
              "
            >
              Create Service Message
            </fr-button>
          </div>
        </div>

        <q-separator vertical />

        <div class="flex flex-1 flex-col gap-2">
          <div class="flex flex-row justify-between gap-2">
            <div></div>

            <fr-button
              icon="fal fa-sync"
              :loading="isFetchingServicesMessages"
              @click="refetch"
            >
              Refresh
            </fr-button>
          </div>
          <q-table
            v-if="servicesMessages"
            :rows-per-page-options="[10, 25, 50]"
            :rows="servicesMessages || []"
            :loading="isFetchingServicesMessages"
            :columns="[
              {
                name: 'message',
                label: 'Message',
                align: 'left',
                field: 'message'
              },
              {
                name: 'user',
                label: 'User',
                align: 'left',
                field: 'user'
              },
              {
                name: 'createdAt',
                label: 'Created At',
                align: 'left',
                field: 'createdAt'
              },
              {
                name: 'actions',
                label: 'Actions',
                align: 'center',
                field: 'actions'
              }
            ]"
            row-key="id"
            wrap-cells
            class="shadow-none"
          >
            <template #body-cell-actions="props">
              <q-td :props="props">
                <div class="flex flex-row flex-nowrap gap-2">
                  <fr-button
                    icon="fal fa-edit"
                    @click="() => console.log('Edit', props.row)"
                  />
                  <fr-button
                    icon="fal fa-trash"
                    :loading="isDeletingServiceMessage"
                    @click="
                      () =>
                        deleteServiceMessage({
                          id: props.row.id
                        })
                    "
                  />
                </div>
              </q-td>
            </template>
          </q-table>
        </div>
      </fr-card-body>
    </fr-card>
  </fr-page>
</template>

<script setup lang="ts">
/**
 * =============================
 * Global Imports
 * =============================
 */
import { useQueryClient } from '@tanstack/vue-query'

/**
 * =============================
 * Local Imports
 * =============================
 */
// import { useAdminStore } from '~/features/admin/store'
import factories from '~/factories'

/**
 * =============================
 * Composables
 * =============================
 */
// const adminStore = useAdminStore()

/**
 * =============================
 * Computed
 * =============================
 */
const newMessage = ref<{
  message: string
  user: string
}>({
  message: '',
  user: ''
})

/**
 * =============================
 * Hooks
 * =============================
 */
const queryClient = useQueryClient()

const {
  data: servicesMessages,
  isFetching: isFetchingServicesMessages,
  refetch
} = factories.admin.queries.useFetchServiceMessages()

const {
  mutateAsync: createServiceMessage,
  isPending: isCreatingServiceMessage
} = factories.admin.mutations.useCreateServiceMessage({
  onSuccess: () => {
    setTimeout(() => {
      queryClient.invalidateQueries({
        queryKey: ['service_messages']
      })
      refetch()
      notifier({
        type: 'success',
        message: 'Service message created successfully'
      })
    }, 1000)

    newMessage.value.message = ''
    newMessage.value.user = ''
  },
  onError: (error) => {
    notifier({
      type: 'error',
      message: error.message
    })
  }
})

const { mutate: deleteServiceMessage, isPending: isDeletingServiceMessage } =
  factories.admin.mutations.useDeleteServiceMessage()
</script>
